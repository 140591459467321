ion-toast.success-toast {
    --background: #0aa863;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #ffffff;
}

ion-toast.success-toast::part(message) {
    font-size: 18px;
}

ion-toast.error-toast {
    --background: #a81f0a;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #ffffff;
}

ion-toast.error-toast::part(message) {
    font-size: 18px;
}

.connect-btn {
  width: 100% !important;
  font-size: 1rem !important;
}

/* center-round-modal */

.center-round-modal.auto-height {
  --border-radius: 15px;
  --width: 25%;
  --height: auto;
}

.center-round-modal.vw90 {
  --width: 90%;
}

.center-round-modal.vw50 {
  --width: 50%;
}

.center-round-modal.vh85 {
  --height: 85vh;
}

.center-round-modal.vh35 {
  --height: 35vh;
}

.center-round-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}

.center-round-modal.auto-height .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}

.stack-modal {
  --box-shadow: 0 0 0 rgba(0, 0, 0, 0.6) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.6) !important;
}

/* scroll */
.show-scroll {
  --offset-bottom: auto !important;
  --overflow: hidden;
  background: var(--ion-toolbar-background, var(--ion-background-color, 
               #000000)) !important;
  overflow: auto;

&::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

&::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

&::-webkit-scrollbar-thumb {
  background: rgb(147, 147, 147);
}

/* &::-webkit-scrollbar-track:hover {
  background: #35d703;
}

&::-webkit-scrollbar-thumb:hover {
   background: #94173a
} */

.inner-scroll {
   scrollbar-width: thin;
}
}

.fancy-text {
  @supports (background-clip: text) or (-webkit-background-clip: text) {
    background-image: 
      url("data:image/svg+xml,%3Csvg width='2250' height='900' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%230096c7' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2390e0ef'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2390e0ef'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%2348cae4'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

/* Design Btn */
.design-btn {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #d5d5d5 7px 7px 0 0,#000 7px 7px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk,Arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.design-btn:focus {
  text-decoration: none;
}

.design-btn:hover {
  text-decoration: none;
}

.design-btn:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

.design-btn:not([disabled]):active {
  box-shadow: #d5d5d5 4px 4px 0 0, #000 4px 4px 0 1px;
  transform: translate(5px, 5px);
}